import React from "react"

import Layout from "../layout/index"
import SEO from "../components/seo"
import "../styles/libs/grid/main.scss"

import choco01 from '../images/chocolates/chocolate_giftset.jpg'
import choco02 from '../images/chocolates/chocolate_flower.jpg'
import choco03 from '../images/chocolates/chocolate_clover.jpg'
import choco04 from '../images/chocolates/chocolate_shell.jpg'
import choco05 from '../images/chocolates/chocolate_handmade.jpg'
import choco06 from '../images/chocolates/chocolate_flower2.jpg'

import cake01 from '../images/cakes/cake.jpg'
import cake02 from '../images/cakes/cake_birthday.jpg'
import cake03 from '../images/cakes/cake_roses_lettering.jpg'
import cake04 from '../images/cakes/cake_birthday_lettering_03.jpg'
import cake06 from '../images/cakes/cake_heartshaped.jpg'
import cake07 from '../images/cakes/cake_rose.jpg'
import cake08 from '../images/cakes/cake_bouncy.jpg'
import cake09 from '../images/cakes/cake_real_flower.jpg'
import cake10 from '../images/cakes/cake_mousse_heart_02.jpg'
import cake11 from '../images/cakes/cake_carrot.jpg'
import cake12 from '../images/cakes/cake_mousse_02.jpg'

import baked01 from '../images/baked/baked_macaron_rainbow.jpg'
import baked02 from '../images/baked/baked_macaron_bouquet.jpg'
import baked03 from '../images/baked/baked_maracon_halloween.jpg'
import baked04 from '../images/baked/custom_macaron.jpg'
import baked05 from '../images/baked/macarons_gifts_02.jpg'
import baked06 from '../images/baked/macarons_gifts.jpg'
import baked07 from '../images/baked/baked_madeline.jpg'
import baked08 from '../images/baked/madeline_cherry.jpg'
import baked09 from '../images/baked/madeline_chestnut.jpg'
import baked10 from '../images/baked/baked_for_party.jpg'
import baked11 from '../images/baked/baked_brownie.jpg'
import baked12 from '../images/baked/baked_cupcakes.jpg'
import baked13 from '../images/baked/baked_quiche.jpg'
import baked14 from '../images/baked/baked_cookies_01.jpg'
import baked15 from '../images/baked/baked_xmas.jpg'
import baked16 from '../images/baked/baked_cheeseballs.jpg'
import baked17 from '../images/baked/baked_seasonal_cookie.jpg'
import baked18 from '../images/baked/maracon_unicorn_pop.jpg'


const ProductPage = () => (
	<div className="is-preload">
			<div id="wrapper" class="main">
					<div id="main">
						<div class="inner">
							<header>
								<h1>Products</h1>
								Treats by Eunice is always exploring for new idea and products!<br /> 
								Theses are some of the past works done.
							</header>

							<h2 id='chocolates'> Chocolates </h2>
							<section class="tiles">
								<article class="style1">
									<span class="image">
										<img src={choco01} alt="" />
									</span>
								</article>
								<article class="style2">
									<span class="image">
										<img src={choco02} alt="" />
									</span>
								</article>
								<article class="style3">
									<span class="image">
										<img src={choco03} alt="" />
									</span>
								</article>
								<article class="style4">
									<span class="image">
										<img src={choco04} alt="" />
									</span>
								</article>
								<article class="style5">
									<span class="image">
										<img src={choco05} alt="" />
									</span>
								</article>
								<article class="style6">
									<span class="image">
										<img src={choco06} alt="" />
									</span>
								</article>

							</section>

							<h2 id='cakes'> Cakes </h2>
							<section class="tiles">
								<article class="style1">
									<span class="image">
										<img src={cake01} alt="" />
									</span>
								</article>
								<article class="style2">
									<span class="image">
										<img src={cake02} alt="" />
									</span>
								</article>
								<article class="style3">
									<span class="image">
										<img src={cake03} alt="" />
									</span>
								</article>
								<article class="style4">
									<span class="image">
										<img src={cake04} alt="" />
									</span>
								</article>
								<article class="style6">
									<span class="image">
										<img src={cake06} alt="" />
									</span>
								</article>
								<article class="style3">
									<span class="image">
										<img src={cake08} alt="" />
									</span>
								</article>
								<article class="style1">
									<span class="image">
										<img src={cake09} alt="" />
									</span>
								</article>
								<article class="style5">
									<span class="image">
										<img src={cake10} alt="" />
									</span>
								</article>
								<article class="style4">
									<span class="image">
										<img src={cake12} alt="" />
									</span>
								</article>
							</section>

							<h2 id='baked'> Baked Sweets </h2>
							<section class="tiles">
								<article class="style1">
									<span class="image">
										<img src={baked01} alt="" />
									</span>
									<div> Custom macarons </div>
								</article>
								<article class="style2">
									<span class="image">
										<img src={baked02} alt="" />
									</span>
									<div> Macaron flower bouquet </div>
								</article>
								<article class="style4">
									<span class="image">
										<img src={baked04} alt="" />
									</span>
									<div> Macaron gift set example </div>
								</article>
								<article class="style6">
									<span class="image">
										<img src={baked06} alt="" />
									</span>
								</article>
								<article class="style2">
									<span class="image">
										<img src={baked07} alt="" />
									</span>
									<div>Madeline gift set</div>
								</article>
								<article class="style3">
									<span class="image">
										<img src={baked08} alt="" />
									</span>
								</article>
								<article class="style1">
									<span class="image">
										<img src={baked09} alt="" />
									</span>
								</article>
								<article class="style5">
									<span class="image">
										<img src={baked10} alt="" />
									</span>
									<div>Custom order cookies</div>
								</article>
								<article class="style6">
									<span class="image">
										<img src={baked11} alt="" />
									</span>
									<div>Brownie</div>
								</article>
								<article class="style4">
									<span class="image">
										<img src={baked12} alt="" />
									</span>
									<div>Party cupcakes</div>
								</article>
								<article class="style5">
									<span class="image">
										<img src={baked13} alt="" />
									</span>
									<div>Quiche</div>
								</article>
								<article class="style6">
									<span class="image">
										<img src={baked14} alt="" />
									</span>
									<div>Almond cookies</div>
								</article>
								<article class="style5">
									<span class="image">
										<img src={baked16} alt="" />
									</span>
									<div>Cheese ball cookies</div>
								</article>
								<article class="style6">
									<span class="image">
										<img src={baked17} alt="" />
									</span>
									<div>Seasonal chocolate chip cookie</div>
								</article>
								<article class="style4">
									<span class="image">
										<img src={baked18} alt="" />
									</span>
									<div>Cake pops</div>
								</article>

							</section>
						</div>
					</div>
			</div>
	</div>
)

export default ProductPage;